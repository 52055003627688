import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from 'components/layout'
import Head from 'components/head'

// import Box from 'components/box';

import 'style/blog.scss'

class Blog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allIn: true,
    }
  }
  render() {
    const { data } = this.props
    const items = data.allItems.edges

    return (
      <Layout locale="nl" className="main-container">
        <Head pageTitle="Nieuws en Blog artikelen" locale="nl" />
        <section className="container blog-container">
          <div className="blog-results">
            {items.map(item => {
              return BlogItem(item.node)
            })}
          </div>
        </section>
      </Layout>
    )
  }
}

const BlogItem = item => {
  const fields = JSON.parse(item.field)
  // console.log({ fields })

  return (
    <article key={item.id} className="blog-article">
      <div className="blog-article-image">
        <Link to={`/blog/${fields.url}`}>
          <img
            src={
              '//cdn.burobork.nl/' +
              fields.fields['Feature afbeelding'][0].reference
            }
          />
        </Link>
      </div>
      <div className="blog-article-content">
        <div className="blog-article-heading">
          <h2 className="bukazu-h2">
            <Link to={`/blog/${fields.url}`}>{item.title}</Link>
          </h2>
          <div className="blog-article-sub"></div>
          <div className="blog-article-meta">
            {fields.fields.Datum},&nbsp;
            <Link to={`/auteur/${fields.fields.Auteur.url}`}>
              {fields.fields.Auteur.title}
            </Link>
          </div>
        </div>
      </div>
    </article>
  )
}

Blog.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BuroBorkBlog {
    allItems(
      filter: { group: { eq: "Artikelen" } }
      sort: { fields: id, order: DESC }
    ) {
      edges {
        node {
          id
          title
          field
        }
      }
    }
  }
`

export default Blog
